import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoaderPage } from "../components/LoaderPage";
import useAuthStore from "../context/useAuthStore";
import ProtectedRoute from "./components/PrivateRoute";

const Login = lazy(() => import("../pages/Login"));
const MainMenu = lazy(() => import("../pages/MainMenu"));
const PartSearchNumber = lazy(() => import("../pages/PartSearchNumber"));
const PartNumber = lazy(() => import("../pages/PartNumber"));
const PartNumberAdd = lazy(() => import("../pages/PartNumberAdd"));
const Checkout = lazy(() => import("../pages/Checkout"));
const Confirmation = lazy(() => import("../pages/Confirmation"));
const ChangeProductQuantity = lazy(() =>
  import("../pages/ChangeProductQuantity")
);
const RequestsNotSent = lazy(() => import("../pages/RequestsNotSent"));
const RequestNotSent = lazy(() => import("../pages/RequestNotSent"));

const LatestResearch = lazy(() => import("../pages/LatestResearch"));

const MainRoutes = () => {
  const { isLogged } = useAuthStore();

  return (
    <BrowserRouter>
      <Suspense fallback={<LoaderPage />}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute validator={isLogged}>
                <MainMenu />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pesquisa-de-pn"
            element={
              <ProtectedRoute validator={isLogged}>
                <PartSearchNumber />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pesquisa-de-pn/:id"
            element={
              <ProtectedRoute validator={isLogged}>
                <PartNumber />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pesquisa-de-pn/:id/add"
            element={
              <ProtectedRoute validator={isLogged}>
                <PartNumberAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute validator={isLogged}>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout/item/:partNum"
            element={
              <ProtectedRoute validator={isLogged}>
                <ChangeProductQuantity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/confirmacao/:id"
            element={
              <ProtectedRoute validator={isLogged}>
                <Confirmation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/requisicoes-nao-enviadas"
            element={
              <ProtectedRoute validator={isLogged}>
                <RequestsNotSent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/requisicoes-nao-enviadas/:id"
            element={
              <ProtectedRoute validator={isLogged}>
                <RequestNotSent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ultimas-pesquisas"
            element={
              <ProtectedRoute validator={isLogged}>
                <LatestResearch />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default MainRoutes;
