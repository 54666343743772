import create from "zustand";
import { persist } from "zustand/middleware";
import { signIn } from "../services/authentication";
// import toast from "react-hot-toast";

const useAuthStore = create(
  persist(
    (set, get) => ({
      loading: false,
      isLogged: false,
      user: {
        user: "",
        password: "",
      },

      login: async (formData, setFieldError) => {
        try {
          set({ loading: true });
          await signIn(formData);

          set({
            isLogged: true,
            user: formData,
          });
        } catch (e) {
          setFieldError("errorMessage", e.response.data.error);
        } finally {
          set({ loading: false });
        }
      },

      setLoading: () => {
        const { loading } = get();
        set({ loading: !loading });
      },

      reset: () => {
        set({
          loading: false,
          isLogged: false,
          user: {
            user: "",
            password: "",
          },
        });
      },
    }),
    {
      name: process.env.REACT_APP_APPLICATION_KEY,
      getStorage: () => localStorage,
    }
  )
);

export default useAuthStore;
