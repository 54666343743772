import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Toaster } from "react-hot-toast";

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/globalImports.css";

import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles";

import defaultTheme from "./styles/theme";
import MainRoutes from "./routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <Toaster />
        <GlobalStyle />
        <MainRoutes />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
