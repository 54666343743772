import axios from "axios";
// import {
//   getAuthenticatedUser,
//   removeAuthenticatedUser,
// } from "../localStorage/authentication";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axios.interceptors.request.use((config) => {
  // const data = getAuthenticatedUser();

  // if (config.data) {
  //   if (config.data.cod_usuario && config.data.cod_usuario !== "") {
  //     config.data["cod_usuario"] = data?.state.user.cod_usuario;
  //   }
  // }

  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response && error.response.data) {
      // if (typeof error !== "undefined") {
      //   if (error.response.status === 401) {
      //     removeAuthenticatedUser();
      //     window.location = "/login/signin/";
      //   }
      // }
    }

    return Promise.reject(error);
  }
);

export default Axios;
