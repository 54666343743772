import { css } from "styled-components";

const theme = {
  colors: {
    white: "#FFF",
    body: "#E5E5E5",
    primary: "#006196",
    success: "#5FC74E",
    danger: "#A82323",
  },

  button: {
    default: "#FFF",
    primary: "#006196",
    success: "#5FC74E",
    danger: "#A82323",
    white: "#FFF",
  },

  fonts: {
    poppins: `'Poppins', sans-serif`,
  },

  textColor: {
    typography: "#706F6D",
    title: "#353535",
    caption: "#A6A6A6",
  },

  shadowCss: css`
    background: #ffffff;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
  `,
};

export default theme;
